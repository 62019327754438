import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
// import CurriculumTabContent from '../../data/course/CurriculumTabContent.json';
import CourseData from '../../data/course/CourseData.json';
import InstructorData from '../../data/instructor/InstructorData.json';
// import { VideoPlayerOne } from '../../components/video-player/VideoPlayerOne';
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";
// import VideoPlayer from '../../components/online-video-player/Player/Player';

const CurriculumTabContent = 
    [
        {
            "title": "Unit 1C",
            "content": [
                "Unit 1C 5 - Paragraph",
                "Unit 1C 5 - Writing",
                "Unit 1C 5 - Answers",
                "Unit 1C 5 - Listening",
                "Unit 1C - Sharing Personal Information",
                "Using Dynamic Data"
            ],
            "links": [
                "https://youtu.be/5z0IvB0wLrA",
                "https://youtu.be/6YUyCI-rizc",
                "https://youtu.be/ruUBMKUOvqc",
                "https://youtu.be/0g_NXj3glv0",
                "https://youtu.be/LKEM-FjvjkE",
                "https://youtu.be/O5JDbNWYybo"
            ]
        },
        {
            "title": "Unit 1D",
            "content": [
                "Introduction",
                "Course Overview"
            ]
        },
        {
            "title": "Unit 1E",
            "content": [
                "Introduction",
                "Course Overview"
            ]
        }
    ];

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}



const CurriculumContent = ({setSelectedVideoUrl ,selectedVideoUrl}) => {
    
    const [activeId, setActiveId] = useState( '0' );
    function toggleActive( id ) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
            {
                CurriculumTabContent.map( ( accordion, i ) => (
                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'bg-active' : ''}`}>
                        <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>{ accordion.title }</CustomToggle>
                        </div>
                        <Accordion.Body bsPrefix="edu-accordion-body">
                            <ul>
                                { 
                                    accordion.content.map( ( title, index ) => (
                                        <li key={index}>
                                            <div 
                                                className="text" 
                                                style={{ cursor: !accordion.links || !accordion.links[index] ? 'default' : 'pointer', color: selectedVideoUrl === (accordion?.links?.[index] || '')? '#5f8eda': '#6f6b80' }}
                                                onClick={(e) => {e.stopPropagation();setSelectedVideoUrl(accordion.links && accordion.links[index]);}}><i className="icon-draft-line"></i>{title}</div>
                                            {!accordion.links || !accordion.links[index] ? (
                                                <div className="icon">
                                                    <i className="icon-lock-password-line"></i>
                                                </div>
                                                ) : null}
                                            {/* <div className="icon"><i className="icon-lock-password-line"></i></div> */}
                                        </li>
                                    ) )
                                
                                }
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                ) )
            }
        </Accordion>
    )
}

const CourseDetailsThree = () => {
    const { id } = useParams();
    const courseId = parseInt( id, 10 );
    const data = CourseData.filter( course => course.id === courseId );
    const courseItem = data[0];
    const videoNode = useRef(null);
    const player = useRef(null);
    const initialized = useRef(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState();
    useEffect(() => {
        if (CurriculumTabContent?.links?.length > 0) {
            setSelectedVideoUrl(CurriculumTabContent.links[0]);
          }
      
      }, [])
    // const initialOptions = {
    //     controls: true,
    //     fluid: true,
    //     controlBar: {
    //       volumePanel: {
    //         inline: false
    //       }
    //     }
    //   };
    const initialOptions = {
        controls: true,
        fluid: true,
        controlBar: {
            volumePanel: {
                inline: false
            }
        }
    };

    const videoJsOptions = {
        sources: [
          {
            type: "video/youtube", //important
            src: selectedVideoUrl || "https://youtu.be/5z0IvB0wLrA"
          }
        ]
      };

    // useEffect(() => {
    //     if (videoNode.current) {
    //         if (!player.current) {
    //             player.current = videojs(videoNode.current, {
    //                 ...initialOptions,
    //                 sources: [{ src: selectedVideoUrl, type: 'video/youtube' }]
    //             }).ready(function () {
    //                 console.log('Player Ready');
    //             });
    //         } else {
    //             player.current.src({
    //                 src: selectedVideoUrl || "https://www.youtube.com/watch?v=GlhV-OKHecI",
    //                 type: 'video/youtube'
    //             });
    //             player.current.load();
    //         }
    //     }

    //     return () => {
    //         if (player.current) {
    //             player.current.dispose();
    //             player.current = null;
    //         }
    //     };
    // }, [selectedVideoUrl]);


        useEffect(() => {
            if (videoNode.current && !initialized.current) {
            initialized.current = true; // Prevent duplicate initialization
            player.current = videojs(videoNode.current, {
                ...initialOptions,
                ...videoJsOptions,
            }).ready(function () {
                console.log('Player Ready');
            });
            } else if (player.current) {
            // Update the player when the video URL changes
            player.current.src({ type: 'video/youtube', src: selectedVideoUrl });
            }
        
            return () => {
            if (player.current) {
                player.current.dispose();
            }
            };
        }, [selectedVideoUrl]);

    // const videoJsOptions = {
    //     autoplay: true,
    //     controls: true,
    //     width: 770,
    //     height: 500,
    //     preload:"auto",
    //     sources: [{
    //       src: 'https://videos.pexels.com/video-files/3125907/3125907-uhd_2560_1440_25fps.mp4',
    //       type: 'video/mp4'
    //     }],
    //   };
    const indexOfInstructor = InstructorData.findIndex( function( instructor ) {
        return slugify( instructor.name ) === slugify( courseItem.instructor );
    } );
    const instructor = InstructorData[indexOfInstructor];
    const instructorExcerpt = instructor.details.substring(0, 157) + "...";

    const handlePlayerReady = (player) => {
        // Player is ready
        console.log('Player is ready: ', player);
      };

    return (
        <>
            <SEO title={ courseItem.title } />
            <Layout>
                <BreadcrumbOne 
                    title="Course Details"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Details"
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className='container'>
                    <div className="row g-5">
                    {/* <div className="custom-container"> */}
                        {/* <div className="row g-5">
                            <div className="col-lg-12">
                                not required will remove later on
                            </div>
                        </div> */}

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                            {/* <div className="row"> */}
                                <div className="addon-flex">
                                    {/* <VideoPlayerOne options={videoJsOptions} onReady={handlePlayerReady} /> add video here */}
                                    <video ref={videoNode} className="video-js" />
                                     {/* <VideoPlayer url={'https://www.youtube.com/watch?v=GlhV-OKHecI'} /> */}
                                </div>
                            {/* </div> */}
                                <div className="col course-details-content">

                                    <h3 className="title">{ courseItem.title }</h3>


                                    <div className="course-details-card">
                                        <div className="course-details-two-content">
                                            <div dangerouslySetInnerHTML={{__html: courseItem.details}} />
                                        </div>
                                    </div>

                                    {/* <div className="course-details-card mt--40">
                                        <div className="course-details-two-content">
                                            <CurriculumContent />
                                        </div>
                                    </div> */}



                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                
                                {/* <CourseInfo data={courseItem}/> */}
                                <div className="course-details-card">
                                        <div className="course-details-two-content">
                                            <CurriculumContent setSelectedVideoUrl={setSelectedVideoUrl} selectedVideoUrl={selectedVideoUrl} />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default CourseDetailsThree;