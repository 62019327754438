import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import { useNavigate } from 'react-router-dom';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';

const PrForm = () => {

    const satBenefits = [
        {
            title: 'Application Evaluation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Documentation Services',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Visa Support',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Interview Coaching',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact-us');
    };
    const sendPrDetails = () => {
        //to do Api call
    }
    return (
        <>
            <SEO title="Pr Form" />
            <Layout>
                <BreadcrumbOne
                    title="Permanent Resident"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Pr"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'Permanent Resident'}
                    mTitle={'About Permanent Resident For Individual Country'}
                    desc_1={"At FETC, we specialize in providing end-to-end support for your PR application, ensuring a seamless experience from start to finish. Our dedicated team brings extensive knowledge and experience to the table, guiding you through every stage of the process with clarity and professionalism."}
                    desc_2={"We offer strategic advice and solutions designed to enhance your application’s success. From initial consultation to final submission, our comprehensive services are crafted to address your requirements and navigate complex immigration requirements effectively. With our expertise, you can confidently move forward in getting your PR."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/visitorVisa.jpg'}
                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">

                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    {/* <CategoryThreeCustom
                                        items={prItems}
                                        customStyle={{justifyContent:'center'}}
                                    /> */}
                                    <CustomCounter
                                        funFacts={satBenefits} />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                    <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => window.open('https://forms.gle/es6Mkua6qGDWVikF7', '_blank')}>
                                        <span>Click Here For Filling Up Pr Form</span>
                                    </button>
                                </div>
            
                            </div>
                        </div>
                    </div>
                {/* </div> */}

            </Layout>
        </>
    )
}
export default PrForm;