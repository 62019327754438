import React,{useState, useEffect} from 'react';
import CourseTypeThree from '../../components/course/CourseTypeThree';



export const fetchMockCourses = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const mockResponse = {
          data: {
            message: [
              {
                name: "elementary",
                title: "Unit 1C",
                tags: [],
                description: "Unit 1 - C",
                image: "Unit1C.png",
                video_link: null,
                short_introduction: "Elementary",
                published: 0,
                upcoming: 1,
                featured: 0,
                disable_self_learning: 0,
                published_on: null,
                status: "In Progress",
                paid_course: 0,
                course_price: 0.0,
                currency: "INR",
                amount_usd: 0.0,
                lesson_count: 6,
                enrollment_count: 2,
                enrollment_count_formatted: 0,
                avg_rating: 0.0,
                instructors: [
                  {
                    name: "bhumikadilkhush@gmail.com",
                    username: "bhumika",
                    full_name: "Bhumika Dilkhush",
                    user_image:
                      "https://secure.gravatar.com/avatar/1312523c3348113cfb3f7fc4338a4b90?d=404&s=200",
                    first_name: "Bhumika",
                  },
                ],
                membership: null,
                is_instructor: false,
              },
            ],
          },
        };
  
        resolve(mockResponse); // Resolve the mock response
      }, 1000); // Simulate network delay
    });
  };

const CourseCustom = () => {
    const [courseItems, setCourseItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatCourseData = (apiResponse) => {
        
        const courses = apiResponse.message;
      
        
        const formattedCourses = courses.map((course, index) => ({
          id: index + 1, // Assigning a unique id
          title: course.title || 'No Title',
          image: course.image || 'house-uk.jpg',
          instructor: course.instructors.length ? course.instructors[0].full_name : 'Unknown Instructor', 
          duration: '3 - 4 Hours', 
          durationInHour: '3 Hours', 
          durationInHourMinute: '3hr 14min', 
          level: 'Available Now', 
          language: 'English', 
          deadline: '25 Dec, 2024', 
          rating: course.avg_rating || 0, 
          student: course.enrollment_count || 0, 
          lesson: course.lesson_count || 0, 
          quizzes: 12, 
          price: course.course_price, 
          oldPrice: '', 
          review: course.avg_rating || 0, 
          passPercentage: 0, 
          featured: course.featured === 1,
          certificate: 'not available', 
          filterParam: '', 
          categories: ['Language Learning', 'Business'], 
          videoLink: course.video_link ? [`https://www.youtube.com/watch?v=${course.video_link}`] : ['https://www.youtube.com/watch?v=pNje3bWz7V8'], // Use provided video link or default
          excerpt: course.short_introduction || 'No introduction available', // Short introduction or default text
          details: `<h5>Course Description</h5> ${course.description || ''}`, // Course description
        }));
      
        return formattedCourses;
      };

      const fetchCourses = async () => {
        try {
            setLoading(true);
            const response = await fetchMockCourses();
            const formattedData = formatCourseData(response.data); 
            setCourseItems(formattedData); 
            setLoading(false);
            } catch (error) {
            console.error('Error fetching courses:', error);
            setCourseItems([]); 
            setLoading(false);
            } finally {
            setLoading(false);
            }
      };

      useEffect(() => {
        fetchCourses();
      }, [])
      
      
    return (
        <>
            {/* <SEO title="Course Style - 3" />
            <Layout>
                <BreadcrumbOne 
                    title="Course Style - 3"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Style - 3"
                /> */}
                <div className="edu-course-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5 mt--10">
                        <h3>Courses</h3>
                        {loading && <div class="d-flex align-items-center">
                            <strong role="status">Loading...</strong>
                            <div class="spinner-border ms-auto" aria-hidden="true"></div>
                                        </div>}
                            {!loading && courseItems &&
                                courseItems.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4" key={ item.id }>
                                        <CourseTypeThree data={item} />
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
            {/* </Layout> */}
        </>
    )
}

export default CourseCustom;