import React , { useState } from 'react';
import emailjs from 'emailjs-com';
import TimePicker from 'react-bootstrap-time-picker';

const Result = () => {
    return (
        <p className="success-message">Thanks for your query. We will contact with you soon.</p>
    )
}

function AppointmentForm( { formStyle } ) {
    const [result, setResult] = useState( false );
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [time, setTime] = useState('');
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');


    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        // Allow only numbers and prevent "e", "-", and "+" characters
        if (!isNaN(value) && !value.includes('e') && !value.includes('-') && !value.includes('+')) {
            setPhoneNumber(value);
        }
      };

    const handleTimePicker = (event) => {
        const value = event.target.value;
        // Allow only numbers and prevent "e", "-", and "+" characters
        if (!isNaN(value) && !value.includes('e') && !value.includes('-') && !value.includes('+')) {
            setTime(value);
        }
      };

    // const sendEmail = ( e ) => {
    //     e.preventDefault();
    //     emailjs
    //     .sendForm(
    //         'service_bxh6md3', 
    //         'template_1g7v07n', 
    //         e.target, 
    //         'user_8Lx0gfI1ktOoeEN8DTV10dee'
    //     )
    //     .then( ( result ) => {
    //         console.log( result.text );
    //         }, 
    //         ( error ) => {
    //             console.log( error.text );
    //         }
    //     );
    //     e.target.reset();
    //     setResult( true );
    // };
    const sendEmail = async (e) => {
        e.preventDefault();
        setTimeout( () => {
            setResult( false );
        }, 5000);
    };

    

    return (
        <form className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
            <div className="col-lg-6">
                <div className="form-group">
                    <input 
                        type="text" 
                        className="form-control form-control-lg" 
                        name="fullname" 
                        placeholder="Name*"
                        onChange={(e)=>{setName(e.target.value)}} 
                        required />
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                    <input 
                        type="email" 
                        className="form-control form-control-lg" 
                        name="email" 
                        placeholder="Email*" 
                        onChange={(e)=>{setEmail(e.target.value)}}
                        required/>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                <label htmlFor="time-picker" className="form-label">Select Time For Appointment</label>
                    
                    <TimePicker
                        start="10:00"
                        end="19:30"
                        step={30}
                        value={time}
                        onChange={handleTimePicker}
                        style={{ fontSize: '16px', backgroundColor: '#F5F5F5', padding:'0 30px',fontWeight: 500, border:'1px solid transparent'}}
                    />
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                <label htmlFor="time-picker" className="form-label">Enter Your Contact Number</label>
                    <input 
                        type="text" 
                        className="form-control form-control-lg" 
                        name="email" 
                        placeholder="Phone number" 
                        maxLength={10}
                        value={phoneNumber}
                        onChange={(e)=>{ 
                            /^[1-9]\d*$/.test(e.target.value) ?
                            setPhoneNumber(e.target.value)
                        :
                        setPhoneNumber('')}}
                        required/>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input 
                        type="text" 
                        className="form-control form-control-lg" 
                        name="topicForConsultation" 
                        placeholder="Topic For Consultation" 
                        onChange={(e)=>{setTopic(e.target.value)}}/>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                    <textarea 
                    className="form-control" 
                    name="message" 
                    placeholder="Specify Your Query Details"
                    onChange={(e)=>{setMessage(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="col-lg-12">
                <button className="rn-btn edu-btn" type="submit">
                    <span>Submit</span><i className="icon-arrow-right-line-right"></i>
                </button>
            </div>
            { result ? <div className="form-group"><Result /></div>  : null }
        </form>
    )
}
export default AppointmentForm;