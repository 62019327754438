import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SyzygyBanner from '../../components/banner/SyzygyBanner';
import CourseCustom from './CourseCustom';

const CoursePage = () => {
    // const CourseItems = CourseData.slice(0, 9);
    return (
        <>
            <SEO title="SyZyGy" />
            <Layout>

                <SyzygyBanner/>
                
                <CourseCustom />
            </Layout>
        </>
    )
}

export default CoursePage;