import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import AppointmentForm from '../../components/contact/AppointmentForm';

const Appointment = () => {
    return (
        <>
            <SEO title="⁠Book An Appointment" />
            <Layout>
                <BreadcrumbOne 
                    title="⁠Book An Appointment"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Appointment"
                />
                <div className="eduvibe-contact-me-top edu-contact-me-area edu-section-gap bg-color-white">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/images/contact/contact-me/contact2.jpg" style={{borderRadius:'100%',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}} alt="Contact Me" />
                                        {/* <div className="content-overlay">
                                            <h4 className="title">Don’t Be Hesitate To <br /> Contact With Us</h4>
                                        </div> */}
                                    </div>
                                    </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title text-start mb--45">
                                    <span className="pre-title">Contact Us</span>
                                    <h3 className="title">Send Us A Message</h3>
                                </div>
                                <AppointmentForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                                {/* <ContactMeForm formStyle="rnt-contact-form rwt-dynamic-form row" /> */}
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-03-11.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-15-06.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-09-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    )
}
export default Appointment;